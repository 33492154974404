#intro {
    height: calc(100vh - 4rem);
    width: 100vw;
    max-width: 75rem;
    margin: 0 auto;
    overflow: hidden;
    position: relative;
    

}

.bg{
    
    position: absolute;
    top: 8rem;
    right: 0;
    z-index: -1 ;
    object-fit: cover;
    height: 70vh;
    margin-top: -3rem;
    margin-left: 2rem;
    
    
}

.introContent
{
    height: 100vh;
    width: 100vh;
    padding: 0rem;
    font-size: 3rem;
    font-weight: 700;
    display: flex;
    flex-direction: column;
    justify-content: center;
}


.introPara{
    font-size: 1.5rem;
    font-weight: 300;
    letter-spacing: 1px;
    padding-right: 1rem;

}

.hello{
    font-size: 1.75rem;
    font-weight: 500;
}

.introName{
    color: yellow;
}

/* .title{
    font-family: 'Lato', sans-serif;
    font-size: 30px;
    width: 0;
    overflow: hidden;
    white-space: no-wrap;
    display:block;
    border-right: 3px solid #ffffff;
    animation: typewriter 6s steps(7) infinite, blinking-cursor 0.8s infinite;
    animation-fill-mode: forwards;
  }

  @keyframes typewriter {
    0% { width:0 }
    25% { width: 50% }
    50% { width: 100% }
  
    80% { width: 100% }
    90% { width: 50%; }
    
    100% { width: 0; }
  }
  
  @keyframes blinking-cursor {
    from { border-color: transparent }
    to { border-color: white }
  } */




.btn{
    background: white;
    margin: 1rem 1rem;
    padding: 0.75rem 1.5rem;
    border: none;
    border-radius: 2rem;
    cursor: pointer;
    scroll-behavior: smooth;
}


.btn2{
    background: white;
    margin: 1rem 0rem;
    /* padding: 0.75rem 1.5rem; */
    border: none;
    border-radius: 2rem;
    cursor: pointer;
}


.btnImg{
    object-fit: cover;
    margin: 0 0.25rem;
    height: 1rem;

}

.btn2Img{
    object-fit: cover;
    height: 1rem;
    width: 1rem;
    margin: 0 0.25rem;
    height: 1rem;

}




/* @media screen and (max-width:1180px) {
    
    .bg{
        right: 3vw;
        height: 60vh;
        top: 9rem;

    }
    
    .introPara{
        font-size: large;
    font-weight: 500;
    letter-spacing: 1px;
        width: 55vw;
        

    }

    .introContent{
        font-size: 3vw;
        padding-left: 1rem;
       
        
    }

    .hello{
        font-size: 1.5vw;
    }


}  */


@media screen and (max-width:820px) {
    
    .bg{
        right: 20vw;
        height: 40vh;
        top: 4rem;

    }
    
    .introPara{
        font-size: large;
    font-weight: 400;
    letter-spacing: 1px;
        width: 100vw;
        /* padding-right: 1.4rem; */

    }

    .introContent{
        font-size: 7vw;
        padding-left: 1rem;
       
        
    }

    /* .introText{
        padding-left: 1rem;

    } */

    .hello{
        font-size: 4.5vw;
    }


}

@media screen and (max-width:750px) {
    
    .bg{
        right: -15vw;
        height: 60vh;
        top: 5rem;
    }


     
    .introPara{
        font-size: medium;
    font-weight: 300;
    letter-spacing: 1px;
        /* width: 100vw;
        padding-right: 1.4rem; */

    }

    .introContent{
        font-size: 10vw;
    }

    .hello{
        font-size: 4vw;
    }


}

@media screen and (max-width:680px) {
    
    .bg{
        right: 3vw;
        height: 70vh;
        top: 4rem;
    }

     
    .introPara{
        font-size: medium;
    font-weight: 400;
    letter-spacing: 1px;
        width: 80vw;

    }

    .introContent{
        font-size: 8vw;
    }

    .hello{
        font-size: 4vw;
        margin-top: 5rem;
    }


}



@media screen and (max-width:450px) {
    
    .bg{
        right: -20vw;
        height: 65vh;
        top: 4rem;

    }

     
    .introPara{
        font-size: medium;
    font-weight: 500;
    letter-spacing: 1px;
        width: 100vw;
        padding-right: 1rem;
        /* padding-left: 1.2rem; */
    

    }

    .introContent{
        font-size: 10vw;
        padding-left: 1rem;
        
    }
    
    .introText{
        /* padding-left: 1rem; */

    }

    .hello{
        font-size: 4.5vw;
        margin-top: 10rem;
        /* padding-left: 1rem; */
    }

    

}
