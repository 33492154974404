/* .App {
  font-family: sans-serif;
  text-align: center;
}
.form{
  display: flex;
  flex-direction: column;
  padding: 20px
}

.form input {
  height: 50px;
  margin-top: 20px;
  border-radius: 10px;
  padding: 5px;

}

.form :last-child{
  width: 30%;
  background-color: green;
} */
.contactPage{
  min-height: calc(100vh - 4rem);
  width: 100vw;
  max-width: 60rem;
  margin: 0 auto;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  
}

.contactPageTitile{
  font-size: 3rem;
  margin-top: 1rem;
  margin-bottom: 1rem;

}

.clientDesc{
  font-weight: 300;
  font-size: 1rem;

}

.clientImgs{
  margin: 1rem 0;
  display: flex;
  flex-wrap: wrap;

}

.clientImg{
  object-fit: cover;
  width: 23%;
  min-width: 9rem;
  margin: auto;
  padding: 0.25rem;
  
}

#client{
  padding: 2rem;

}

.contactDesc{
  padding: 1rem;
  font-size: medium;
  font-weight: 300;

}

.contactForm{
  margin: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 90vw;
  max-width: 60rem;

}

.Name, .Email, .Message {
  font-size: medium;
  width: 100%;
  max-width: 40rem;
  margin: 0.5rem;
  padding: 0.5rem 1rem;
  color: white;
  border: none;
  border-radius: 0.5rem;
  background: rgb(40, 40, 40);


}

.submitBtn{
  background: white;
  color: #000;
  border: none;
  border-radius: 0.5rem;
  margin: 2rem;
  padding: 0.75rem 3.5rem;
  cursor: pointer;
  
}

.links{
  display: flex;
  flex-wrap: wrap;

}

.link{
  object-fit: cover;
  height: 3.5rem;
  width: 3.5rem;
  margin: 0 0.75rem;

}
.mailf{
  margin: 0.25rem;

}

.mailicon{
  display: block;
  margin: 1rem;
  height: 113%;
  width: 100%;
}

.mail{
  margin: 0 0 2.5rem 1rem;
  color: white;
}